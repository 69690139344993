import {useState} from 'react';
import {doc,collection,updateDoc,setDoc} from 'firebase/firestore';
import {db,storage} from '../firebase';

import Order from './Order';

const Orders=()=>{
  const [note,setNote]=useState('');
  var totalPrice=0;
  window.basket.map((i)=>{
    totalPrice+=i.orderPrice?i.orderPrice:0
  });

  function checkOrder(){
    window.setLoading(true);
    const time=window.functions.makeID();
    const basket=[];
    window.basket.map((i)=>{
      totalPrice+=i.orderPrice?i.orderPrice:0;
      basket.push({
        barcode:i.barcode,
        barcode2:i.barcode2,
        name:i.name,
        company:i.company,
        group:i.group,
        group2:i.group2,
        orderProductID:i.orderProductID,
        orderColor:i.orderColor,
        orderColor2:i.orderColor2,
        orderPrice:i.orderPrice,
        orderInformation:i.orderInformation,
      })
    })

    const place=
    window.auth=='admin'?'Fabrika':
    window.auth=='factorystore'?'Fab. Mağaza':
    window.auth=='manager'?'Mağaza':
    window.auth=='sales'?'Toptan':null;

    setDoc(doc(db,'orders',time),{
      orderID:time,
      auth:window.auth,
      added:Date.now()-window.timeDif,
      updated:Date.now()-window.timeDif,
      place:place,
      statue:'Bekleniyor',
      note:note,
      basket:basket
    }).then(()=>{
      window.setLoading(false);
      window.setBasket([]);
      window.setAlert({message:"Sipariş alındı."});
    })
  }

  return(
    <div style={{width:'100%',height:'100%',justifyContent:'start'}}>
      <div style={{width:'100%',maxWidth:700,display:'block',justifyContent:'start'}}>
        {<ListOrders/>}
        {window.basket[0]?
        <div style={{}}>
          <div style={{width:'100%',flexDirection:'row',justifyContent:'space-evenly'}}>
            <input
            style={{width:250,background:window.color.five}}
            value={note}
            placeHolder={'Sipariş notu.'}
            onChange={(e)=>{setNote(e.target.value)}}/>
            <div style={{fontSize:12,background:window.color.five,borderRadius:5,margin:5,padding:5}}>
              Toplam = {totalPrice?totalPrice.toLocaleString():'0'} TL
            </div>
          </div>
        </div>
        :
        <div style={{width:'100%',height:'calc(100vh - 40px)'}}>
          Sepette ürün bulunmamakta
        </div>
        }
        {window.basket[0]?
        <div style={{position:'fixed',flexDirection:'row',bottom:45,right:5}}>
          <button onClick={()=>{
            window.setQuestion({message:'Sepeti Onayla?',thisFunction:checkOrder})
          }} style={{width:30,height:30,borderRadius:7,marginLeft:3}}>
            <img
            style={{height:20,width:20}}
            src={require('../Assets/CheckIcon.png')}/>
          </button>
        </div>
        :null}
      </div>
    </div>
  );
}


const ListOrders=()=>{
  return window.basket.map((order,index)=>{
    return(
      <Order key={index} order={order}/>
    );
  })
}



export default Orders;
