import {useState,useEffect} from 'react';
import {doc,collection,query,getDocs,where,updateDoc} from 'firebase/firestore';
import {db} from '../firebase';

const Store=()=>{
  const [data,setData]=useState(window.doc.orders??[]);

  useEffect(()=>{
    getOrders();
  },[])

  window.getOrders=getOrders;
  function getOrders(){
    window.setLoading(true);
    const d=[];
    const q=[collection(db,'orders')];
    if(window.auth!='admin'){
      q.push(where('auth','==',window.auth))
    }
    getDocs(query(...q)).then((doc)=>{
      doc.forEach((i)=>{
        d.push(i.data());
      })
      setData(d);
      console.log(d);
      window.doc.orders=d;
      window.setLoading(false);
    })
  }

  return(
    <div style={{width:'100%',height:'100%',maxWidth:700,display:'block',justifyContent:'start',paddingBottom:200}}>
      {data.map((order)=>{
        return(
          <Orders order={order}/>
        );
      })}
    </div>
  );
}

export default Store;



const Orders=({order})=>{
  const place=
  order.auth=='admin'?'Fabrika':
  order.auth=='factorystore'?'Fab. Mağaza':
  order.auth=='manager'?'Mağaza':
  order.auth=='sales'?'Toptan':null;

  var totalPrice=0;
  order.basket.map((i)=>{
    totalPrice+=i.orderPrice?i.orderPrice:0;
  })

  function cancel(){
    updateDoc(doc(db,'orders',order.orderID),{
      statue:'İptal',
      updated:Date.now()-window.timeDif,
    }).then(()=>{
      window.setAlert({message:"Sipariş Iptal edildi."});
      window.getOrders();
    }).catch((e)=>{window.setAlert({message:`Güncellemede hata: ${e}`})})
  }

  return(
    <div style={{width:'100%',background:window.color.five,borderRadius:20,marginBottom:5,paddingBottom:10,justifyContent:'flex-start',fontSize:13}}>
      <div style={{flexDirection:'row',justifyContent:'space-between',width:'95%',paddingBottom:10,paddingTop:10}}>
        <div style={{}}>
          <div style={{}}>Sipariş Kodu</div>
          <div style={{fontWeight:'700'}}>{order.orderID}</div>
        </div>
        {window.auth=='admin'?
        <div style={{}}>
          <label onClick={()=>{window.setAdd({item:order,action:'editorder'})}} style={{height:30,width:30,fontSize:10,width:50}}>
            <img
            style={{height:20,width:20}}
            src={require('../Assets/EditIcon.png')}/>
            Güncelle
          </label>
        </div>
        :
        <div style={{display:order.statue!='İptal'?'flex':'none'}}>
          <label onClick={()=>{window.setQuestion({message:'Siparişi iptal et?',thisFunction:cancel})}} style={{height:30,width:30,fontSize:10,width:50}}>
            <img
            style={{height:20,width:20,transform:'rotate(45deg)'}}
            src={require('../Assets/PlusIcon.png')}/>
            Iptal et
          </label>
        </div>
        }
      </div>
      <div style={{flexDirection:'row',width:'95%',paddingBottom:10,paddingTop:10}}>
        <div style={{flex:1}}>
          <div style={{}}>Nereye</div>
          <div style={{fontWeight:'700'}}>{place}</div>
        </div>
        <div style={{flex:1}}>
          <div style={{}}>Durum</div>
          <div style={{fontWeight:'700'}}>{order.statue}</div>
        </div>
        <div style={{flex:1}}>
          <div style={{}}>Ürün adeti</div>
          <div style={{fontWeight:'700'}}>{order.basket.length}</div>
        </div>
      </div>
      <div style={{flexDirection:'row',width:'95%',paddingBottom:10,paddingTop:10}}>
        <div style={{flex:1}}>
          <div style={{}}>Eklenme</div>
          <div style={{fontWeight:'700'}}>{window.functions.timeSince(order.added)}</div>
        </div>
        <div style={{flex:1}}>
          <div style={{}}>Güncelleme</div>
          <div style={{fontWeight:'700'}}>{window.functions.timeSince(order.updated)}</div>
        </div>
      </div>
      <div style={{flexDirection:'row',width:'95%',paddingBottom:10,paddingTop:10}}>
        <div style={{flex:1}}>
          <div style={{}}>Toplam Fiyat</div>
          <div style={{fontWeight:'700'}}>{totalPrice.toLocaleString()} TL</div>
        </div>
      </div>
      <div style={{display:'block',width:'90%',padding:5,margin:10,background:window.color.four2,borderRadius:10}}>
        <div style={{width:'100%',flexDirection:'row',justifyContent:order.basket.length>3?'start':'center'}}>
          {order.basket.map((o,index)=>{
            return(
              <EachOrder o={o} index={index}/>
            );
          })}
        </div>
      </div>
      {order.note?
        <div style={{flex:1}}>
          <div style={{}}>Sipariş notu</div>
          <div style={{fontWeight:'700'}}>{order.note}</div>
        </div>
      :null}

    </div>
  );
}

const EachOrder=({o,index})=>{
  return(
    <div style={{minWidth:'32.7%',maxWidth:'32.7%',height:150,background:window.color.five,borderRadius:10,margin:1}}>
      <div style={{width:'100%',height:'100%',marginTop:5,marginBottom:5}}>
        <div style={{fontSize:10,height:'100%',justifyContent:'space-between'}}>
          <div style={{flex:1,fontWeight:'500'}}>
            ({index+1}) - {o.orderProductID}
          </div>
          <div style={{flex:2}}>
            {o.company+(o.group?'/'+(o.group+(o.group2?'/':'')+o.group2):'')}{o.name?'/'+o.name:'İsimsiz'}
          </div>
          <div style={{flex:1}}>
            {o.orderColor?o.orderColor:''}{o.orderColor&&o.orderColor2?'/':''}{o.orderColor2?o.orderColor2:'-'}
          </div>
          <div style={{flex:2}}>
            {o.orderInformation?o.orderInformation:'-'}
          </div>
          <div style={{flex:1,fontWeight:'500'}}>
            {o.orderPrice?o.orderPrice.toLocaleString()+' TL':'-'}
          </div>
        </div>
      </div>
    </div>
  );
}
