import {useState} from 'react';

const Order=({order})=>{
  return(
    <div style={{width:'100%',background:window.color.five,borderRadius:20,marginBottom:5,paddingBottom:10}}>
      <div style={{width:'95%',padding:10,justifyContent:'start',flexDirection:'row',justifyContent:'space-between'}}>
        <div style={{fontSize:12}}>
          <div style={{fontWeight:'600',textTransform:'Uppercase'}}>
            {order.name==''?'İsimsiz':order.name}
          </div>
          <div style={{fontSize:10}}>
            {order.company+(order.group?'/'+(order.group+(order.group2?'/':'')+order.group2):'')}
          </div>
        </div>
        {window.auth=='admin'?
        <button onClick={()=>{
          window.setBasket((prev)=>{
            const newPrev=[...prev].filter((o)=>o!=order);
            return newPrev;
          })
        }} style={{background:0,orderColor:'black',fontSize:10}}>
          <img
          style={{width:20}}
          src={require('../Assets/GarbageIcon.png')}/>
          Çıkar
        </button>
        :null}
      </div>
      <div style={{width:'100%'}}>
        <div style={{width:'100%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          <div style={{flex:1}}>
            Barkod
            <div style={{fontWeight:'700'}}>{order.orderProductID}</div>
          </div>
          <div style={{flex:1}}>
            Tür-1
            <div style={{fontWeight:'700'}}>{order.orderColor?order.orderColor:'-'}</div>
          </div>
          <div style={{flex:1}}>
            Tür-2
            <div style={{fontWeight:'700'}}>{order.orderColor2?order.orderColor2:'-'}</div>
          </div>
          <div style={{flex:1}}>
            Fiyat
            <div style={{fontWeight:'700'}}>{order.orderPrice?order.orderPrice.toLocaleString():'0'} TL</div>
          </div>
        </div>
        <div style={{width:'70%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          <div style={{flex:1}}>
            Ek bilgi
            <div style={{fontWeight:'700'}}>{order.orderInformation?order.orderInformation:'-'}</div>
          </div>
        </div>
        <div style={{width:'100%',flexDirection:'row',justifyContent:'space-between',fontSize:12,paddingTop:10,paddingBottom:10}}>
          {order.addition?
          <div style={{flex:1}}>
            Ek Bilgi
            <div style={{fontWeight:'700'}}>{order.addition}</div>
          </div>
          :null}
        </div>
      </div>
    </div>
  );
}

export default Order;
